import React, { useEffect, useState } from "react";
import "../../assets/css/contact.css";
import axios from 'axios';
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { FaTelegramPlane } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import Carousels from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { VALIDATION_PATTERN } from "../Signin/Store";
import { Cotactus } from "../Helper/Api";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import tuittwrimg from "../../assets/images/twitter-x.png"


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Contactus = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [load, setLoad] = useState(false);



  const Submit = (data) => {
    setLoad(true);
    Cotactus(data)
      .then((res) => {
        if (res.data.status == 1) {
          setLoad(false);
          NotificationManager.success(res.data.message, "Success");
          reset();
        } else if (res.data.status == 0) {
          setLoad(false);
          NotificationManager.error(res.data.message, "Failed");
          reset();
        } else {
          setLoad(false);
          NotificationManager.error(res.data.message, "Failed");
          reset();
        }
      })
      .catch((err) => {
        reset();
        setLoad(false);
        NotificationManager.error("Some Error Occured", "Failed");
      });
  };

  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: "smooth",
      });
    }, 100);
  }, []);


  const [country, setCountry] = useState('');

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get('https://ipinfo.io?token=7bb1a2a17f6376');
        if (response) {
          setCountry(response?.data?.country);
        } else {
          setCountry('IN');
        }
        // // if (!response.ok) {
        // //   throw new Error('Network response was not ok');
        // // }
        // console.log(response.data.country)

        // // const data = await response.json();

      } catch (error) {
        console.log(error);
      }
    };

    fetchCountry();
  }, []);

  return (
    <div>
      <NotificationContainer />
      <div className="contact us">
        <section className="contact banner2">
          <div className="contactcont">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center mt-5">
                  <h1 className=" mb-2 mt-5">Contact Us</h1>
                  <p className=" mb-3">Let's build something great together</p>
                </div>
                <div className="col-md-12 col-lg-6 mt-5">
                  <img
                    src={require("../../assets/images/contact/contact22.png")}
                    alt="logo"
                    className="img-fluid mt-5"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="contact map mt-5">
          <div className="container">
            <div className="row">
              <div className="mapcontent text-center">
                <h2 className="mb-3">Contact Us</h2>
                <p>
                  We are around the clock to help you find the correct answers
                  to your queries. Our FAQ page answers all your questions and
                  gives you clear guidance. Our dedicated team will support you
                  at any time.
                </p>
              </div>
            </div>
          </div>
          <div className="mapform">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <img
                    src={require("../../assets/images/contact/map2.gif")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="contact_form pt-2">
                    <form
                      action="/contact-us.php"
                      method="post"
                      onSubmit={handleSubmit(Submit)}
                    >
                      <div className="form-group">
                        <label className="gradient-text">Your Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          aria-describedby="emailHelp"
                          required=""
                          placeholder="Enter Your name"
                          {...register("name", {
                            required: {
                              value: true,
                              message: "Name is required",
                            },
                            pattern: {
                              value: /^(?=.[a-zA-Z +\s])[a-zA-Z0-9].{1,30}$/i,
                              message: " Enter Valid  Name",
                            },
                          })}
                        />
                        <p className="text-danger">
                          {errors.name && (
                            <span role="alert">Name is required</span>
                          )}
                        </p>
                      </div>
                      <div className="form-group">
                        <label className="gradient-text">Your Email</label>
                        <input
                          type="email"
                          className="form-control"
                          name="name"
                          id="name"
                          aria-describedby="emailHelp"
                          required=""
                          placeholder="Enter Your Email Id"
                          {...register("email", {
                            required: {
                              value: true,
                              message: "Email is required",
                            },
                            pattern: {
                              value: VALIDATION_PATTERN.EMAIL,
                              message: "Enter Valid Email",
                            },
                          })}
                        />
                        <p className="text-danger">
                          {errors.email && (
                            <span role="alert">Email is required</span>
                          )}
                        </p>
                      </div>
                      <div className="form-group">
                        <label className="gradient-text">
                          Your Phone Number
                        </label>
                        <input
                          type="phone"
                          className="form-control"
                          name="phone"
                          id="name"
                          aria-describedby="emailHelp"
                          required=""
                          placeholder="Enter Your Phone Number"
                          {...register("mobile_no", {
                            required: {
                              value: true,

                              message: "Please enter your phone number",
                            },
                            // pattern: {
                            //   value: /^[0-9\s]*$/,
                            //   maxLength: 6,
                            //   message: "Please enter min 6 phone number",
                            // },
                          })}
                          onKeyPress={(event) => {
                            if (
                              !/[0-9 ]+$/.test(event.key) ||
                              event.key === " "
                            ) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <p className="text-danger">
                          {errors.mobile_no && errors.mobile_no.message}
                        </p>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className={`form-check-input ${errors.checkbox ? "border-danger" : ""
                            }`}
                          id="existing_customer"
                          name="existing_customer"
                          {...register("subject", {
                            required: { value: true },
                          })}
                        />
                        <label
                          className={`form-check-label gradient-text ${errors.subject ? "text-danger" : ""
                            }`}
                          htmlFor="exampleCheck1"
                        >
                          Existing Customer{" "}
                          <span>( If Yes, please write your MT5 ID)</span>
                        </label>
                      </div>
                      <div className="form-group pt-3">
                        <label className="gradient-text">Comments</label>
                        <textarea
                          type="text"
                          name="comments"
                          id="comments"
                          required=""
                          className="form-control"
                          placeholder="Enter a comment"
                          defaultValue={""}
                          {...register("msg", {
                            required: {
                              value: true,
                              message: "Please enter a comment",
                            },
                          })}
                        />
                        <p className="text-danger">
                          {errors.msg && (
                            <span role="alert">Please enter a comment</span>
                          )}
                        </p>
                      </div>
                      {load ? (
                        <>
                          {" "}
                          <div className="button ">
                            <button
                              type="button"
                              name="submit"
                              className="btn more-btn"
                            >
                              Loading...
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="button ">
                            <button
                              type="submit"
                              name="submit"
                              className="btn more-btn"
                            >
                              Send Message
                            </button>
                          </div>
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ourteam support mt-5">
          <div className="container">
            <div className="row">
              <div className="team text-center">
                <h2 className="mb-3"> Our support team</h2>
                <p>
                  Our dedicated support specialists speak 16 languages. Support
                  is available 24 hours a day, 7 days a week in English,
                  Chinese, Thai, Vietnamese and Swahili. Check all local support
                  working hours in the table below..
                </p>
              </div>
            </div>
            <Carousels
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              autoPlay={true}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlaySpeed={1500}
              keyBoardControl={true}
              className="slider1 mt-5"
              slidesToShow={1}
              slidesToScroll={1}
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              <div className="contactsli1">
                <img
                  src={require("../../assets/images/contact/support1.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>

              <div className="contactsli2">
                <img
                  src={require("../../assets/images/contact/support3.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
              <div className="contactsli3">
                <img
                  src={require("../../assets/images/contact/support4.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
              <div className="contactsli4">
                <img
                  src={require("../../assets/images/contact/support5.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
              <div className="contactsli5">
                <img
                  src={require("../../assets/images/contact/support6.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
              <div className="contactsli6">
                <img
                  src={require("../../assets/images/contact/support7.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
            </Carousels>
          </div>
        </div>
        <section className="contactvantage mt-5">
          <div className="container">
            <div className="contact_us_row1">
              <div className="inner">
                <h2 className="mb-3">Contact Milliva</h2>
                <p className="contconpara">
                  You have questions or would like more information, please get
                  in touch with us through one of the methods below. We are
                  available 24 hours, 7 days a week to assist.
                </p>
                <div className="contactmilliva mt-4">
                  <div className="row">
                    <div className="col-lg-4 col-sm-12 col-md-12 mt-3">
                      <div className="text_box">
                        <img
                          src={require("../../assets/images/contact/msg.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        <a href="mailto:support@milliva.com" target="_blank" style={{ color: "inherit" }}>
                          support@milliva.com
                        </a>
                        {country !== "IN" ? <p className="mt-2" style={{ color: "inherit", 'fontWeight': 'bold' }}>
                          UAE: +971 50 228 4750
                          <br></br>
                          India: +91 96299 47607
                        </p> : <></>}
                        <p className="mt-3">
                          Reach us through support@milliva.com, and we'll get
                          back to you within 24 hours. If you are an existing
                          client, provide your account number and support PIN
                          for our reference.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-sm-12 col-md-12 mt-3">
                      <div className="text_box">
                        <img
                          src={require("../../assets/images/contact/address.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        <a>Address</a>

                        {country == "IN" ?
                          <>
                            <p>
                              {" "}
                              Milliva Ltd: <br />
                              <span className="fw-bolder">
                                Union of Cormoros of company number 15696,
                              </span>

                              <br />
                              U.K Incorporation company number 13546193,
                              <br /> Trading address, 20-22 Wenlock road,
                              <br />
                              London, N1 7GU- England
                            </p>

                          </>
                          : <>
                            <p >
                              Union of Cormoros of company number 15696,
                              <br /> Hamchako, Mutsamudu, Autonomous Islan Anjouan Union of Comoros,
                            </p>
                            <p>
                              <br /> Kandhaswarna Mega Mall, 2nd  Floor,
                              <br />Saradha College Road, Hastampatty, Salem – 636016
                              <br />TamilNadu, India.
                            </p>
                          </>
                        }
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 mt-3">
                      <div className="text_box">
                        <img
                          src={require("../../assets/images/contact/chat.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        <a>Live Chat</a>
                        <p className="mt-3">
                          If you can't find the answers you are searching for,
                          ask our support team in live chat. If you are an
                          existing client, provide your account number and
                          support PIN for our reference.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="media mt-5 mb-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="media icon d-flex">
                  <h4>Join our community</h4>
                </div>
              </div>
              <div className="col-lg-2 ">
                <ul className="social-media">
                  <li>
                    <i className="confa">
                      <a
                        href="https://www.facebook.com/Millivaforex"
                        target="_blank"
                      >
                        <FaFacebookF />
                      </a>
                    </i>
                  </li>
                  <li>
                    <i className="confa twitter-x">
                      <a href="https://mobile.twitter.com/milliva_fx/with_replies">

                        <img src={tuittwrimg} alt="" />
                      </a>
                    </i>
                  </li>
                  <li>
                    <i className="insta">
                      <a href="https://www.instagram.com/millivafx/">
                        <FaInstagram />
                      </a>
                    </i>
                  </li>
                  <li>
                    <i className="confa">
                      <a href="https://in.pinterest.com/millivam/">
                        <FaPinterest />
                      </a>
                    </i>
                  </li>
                  <li>
                    <i className="confa">
                      <a href="https://t.me/MILLIVAFX">
                        <FaTelegramPlane />
                      </a>
                    </i>
                  </li>
                  <li>
                    <i className="youtube">
                      <a href="https://www.youtube.com/channel/UCdd_R2Jinyr018UOZej899g">
                        <FaYoutube />
                      </a>
                    </i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <section class="contact get-ready mt-5">
  <div class="container">
    <h2>Get Ready</h2>
    <div class="ready-buttons justify-content-center">
<button type="button" class="btn btn-g-ready">button 1</button>
<button type="button" class="btn btn-g-ready">button 2</button>
<button type="button" class="btn btn-g-ready">button 3</button>
<button type="button" class="btn btn-g-ready">button 4</button>
    </div>
  </div>
</section> */}
      </div>
    </div>
  );
};

export default Contactus;
